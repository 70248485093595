<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Employee Payroll</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name: 'employees'}">Employees</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name:'employee-details', params:{id:$route.params.emp_id}}">
                        {{employee_name}} [IES-{{$route.params.emp_id}}]
                    </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Payroll</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            
            <b-row>
                <b-col lg="3" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Year</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="year"
                                :options="years"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>

                <b-col lg="1" class="mb-1rem">
                    <button class="btn btn-primary" @click="onSelection()">
                        Filter
                    </button>
                </b-col>
            </b-row>
                <b-table
                    show-empty
                    striped
                    hover
                    :items="items"
                    :fields="fields"
                    responsive
                    @row-clicked="rowClick"
                    tbody-tr-class="pointer-cursor"
                >
                    <template v-slot:cell(employee.full_name)="row">
                         {{row.item.employee.full_name}} [IES-{{row.item.emp_id}}]
                    </template>
                    <template v-slot:cell(month)="row">
                        {{getMonthName(row.item.month)}}
                    </template>
                    <template v-slot:cell(generated_by)="row">
                        <div v-if="row.item.generated_by">{{row.item.generated_by_employee.full_name}}</div>
                        <div v-else>System</div>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <div>
                            <router-link
                                :to="{name:'payroll-details', params: {emp_id:row.item.emp_id,id:row.item.id}}"
                            > 
                                <button class="btn-sm btn rounded-circle btn-info mr-2" v-b-tooltip.hover title="View Payslip Details"> 
                                    <font-awesome-icon icon="eye" size="1x"></font-awesome-icon>
                                </button>
                            </router-link>
                        </div>
                    </template>
                </b-table>
                <router-link class="float-right" :to="{ name: 'test-payroll', params: { emp_id: $route.params.emp_id } }" v-if="hasPermission('CAN_TEST_PAYROLL')">
                    <button class="btn btn-danger"  @click="onSelection()">
                        Test Payroll
                    </button>
                </router-link>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            year: new Date().getFullYear(),
            api_error: "",
            fields: [
                {key: "employee.full_name", label:"Employee"},
                { key: "month"},
                { key: "gross_salary"},
                {key: "net_pay"},
                {key: "generated_by", label:'Generated By'},
                {key: "updated_at", label:'Generated At'},
                {key: "actions", label:'Actions'}
            ],
            items: null,
            employee_name:null,
            filter: null,
            access: true,
            years: [],
            total_years:3,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_PAYROLL");
        this.getYears();
        this.getEmployee();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getMonthName(number){
            let month_number = parseInt(number) -1
            let mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            return mlist[month_number];
        },

        getYears() {
            const currentYear = (new Date()).getFullYear()+1;
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
            this.onPageLoad();
        },

        getDefaultFilters() {
            let api_params = {
                year: this.year
            };
            return api_params;
        },

        onPageLoad() {
            const api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.getDefaultFilters();
            this.year = api_params.year;
            this.getPayroll(api_params);
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },

        getEmployee: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {};
            let data = {
                id: this.$route.params.emp_id
            }
            query["params"] = data;
            this.axios.get(this.$api.get_employees, query).then(response => {
                this.employee_name=response.data.data.full_name;
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        getPayroll(params) {
            this.items = [];
            this.startProgressBar();
            var query = this.getAuthHeaders();
            params['emp_id'] = this.$route.params.emp_id
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.get_payroll, query).then(response => {
                this.items = response.data.data;
                this.updateProgressBar(true);
                delete params.emp_id;
            this.$router.push({
                path: this.$route.params[0],
                query: this.deleteEmptyKeys(params)
            }).catch(()=>{})
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        onSelection() {
            this.getPayroll(this.getDefaultFilters());
        },

        rowClick(item){
            this.$router.push({name:'payroll-details', params: {emp_id:item.emp_id, id:item.id}});
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
.pointer-cursor {
    cursor:pointer !important;
}

</style>
